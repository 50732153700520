import { Menu } from 'antd';
import { type MenuItemType } from 'antd/es/menu/interface';
import React from 'react';

import Link from './link';

function SidebarOrders(): JSX.Element {
  const menuItems: MenuItemType[] = [
    {
      key: 'all',
      label: <Link to="/orders/all" label="All" />,
    },
    {
      key: 'created',
      label: <Link to="/orders/created" label="Created" />,
    },
    {
      key: 'paid',
      label: <Link to="/orders/paid" label="Paid" />,
    },
    {
      key: 'shipped',
      label: <Link to="/orders/shipped" label="Shipped" />,
    },
    {
      key: 'delivered',
      label: <Link to="/orders/delivered" label="Delivered" />,
    },
    {
      key: 'cancelled',
      label: <Link to="/orders/cancelled" label="Cancelled" />,
    },
    {
      key: 'refunded',
      label: <Link to="/orders/refunded" label="Refunded" />,
    },
  ];

  return <Menu items={menuItems} />;
}

export default SidebarOrders;
