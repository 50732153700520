import { green, red, yellow } from '@ant-design/colors';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  type ICurrencyType,
  type IReportWarehouseProductsPairing,
  type IWarehouseDetail,
} from '@mahawi/eshop-common';
import { formatCurrency } from '@mahawi/eshop-common/dist/src/format-currency';
import { round } from '@mahawi/eshop-common/dist/src/round';
import { stringProbableMatch } from '@mahawi/eshop-common/dist/src/string-probable-match';
import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import {
  Button,
  Divider,
  Popconfirm,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { type DefaultOptionType } from 'antd/es/select';
import { type ColumnsType } from 'antd/es/table';
import { BaseType } from 'antd/es/typography/Base';
import dayjs from 'dayjs';
import React, { Key, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import Link from '../components/link';
import Table from '../components/table';
import { renderProductCode } from '../fragments/table-product-code';
import { RootState } from '../reducers';
import {
  downloaderSyncWarehouseProduct,
  downloaderWarehouseProductPairWithExistingProduct,
} from '../reducers/downloader/actions';
import { type ILanguageState } from '../reducers/language/types';
import { reportWarehouseProductsPairingLoad } from '../reducers/report/actions';
import { type IReportState } from '../reducers/report/types';
import { type IWarehouseState } from '../reducers/warehouse/types';

interface DataType extends IReportWarehouseProductsPairing {
  key: string;
  isMpnSame: boolean;
  isBrandSame: boolean;
  nameProbableMatch: number;
  productLocaleName: string;
  currencyType?: ICurrencyType;
}

function ReportWarehouseProductsPairingContainer({
  dispatch,
  Report,
  Warehouse,
  Language,
}: {
  dispatch: Dispatch;
  Report: IReportState;
  Warehouse: IWarehouseState;
  Language: ILanguageState;
}): JSX.Element {
  const [warehouseOptions, setWarehouseOptions] = useState<DefaultOptionType[]>(
    [],
  );
  const [warehouseUuid, setWarehouseUuid] = useState<string | null>(null);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);
  const [enabledBrandCheck, setenabledBrandCheck] = useState<boolean>(true);
  const [nameProbableMatchThreshold, setNameProbableMatchThreshold] =
    useState<number>(0.25);

  const productIsPairable = useCallback(
    (record: DataType): boolean => {
      let isPairable: boolean =
        record.isBrandSame &&
        record.isMpnSame &&
        record.nameProbableMatch >= nameProbableMatchThreshold;

      if (!enabledBrandCheck) {
        isPairable =
          record.isMpnSame &&
          record.nameProbableMatch >= nameProbableMatchThreshold;
      }

      return isPairable;
    },
    [enabledBrandCheck, nameProbableMatchThreshold],
  );

  useEffect((): void => {
    const warehouseOptionsUE: DefaultOptionType[] =
      Warehouse.warehouses
        ?.filter((warehouse: IWarehouseDetail): boolean => warehouse.isActive)
        .map(
          (warehouse: IWarehouseDetail): DefaultOptionType => ({
            label: warehouse.name,
            value: warehouse.uuid,
          }),
        ) || [];

    setWarehouseOptions(warehouseOptionsUE);
  }, [Warehouse.warehouses, Warehouse.updatedAt]);

  useEffect((): void => {
    const ct: ICurrencyType | undefined = Warehouse.warehouses?.find(
      (warehouse: IWarehouseDetail): boolean =>
        warehouse.uuid === warehouseUuid,
    )?.currencyType;

    const dataSourceUE: DataType[] =
      Report.warehouseProductsPairing?.map(
        (warehouseProduct: IReportWarehouseProductsPairing): DataType => {
          const productLocaleName: string | null = getTranslation(
            warehouseProduct.product.names,
            Language.languageType,
          );

          const nameProbableMatch: number = stringProbableMatch({
            first: warehouseProduct.warehouseProduct.name,
            second: productLocaleName,
          });

          const isMpnSame: boolean =
            warehouseProduct.warehouseProduct.mpn.toLocaleLowerCase() ===
            warehouseProduct.product.mpn.toLocaleLowerCase();

          const isBrandSame: boolean =
            warehouseProduct.warehouseProduct.brand.toLocaleLowerCase() ===
            warehouseProduct.product.brand.toLocaleLowerCase();

          return {
            key: warehouseProduct.warehouseProduct.uuid,
            ...warehouseProduct,
            isMpnSame,
            isBrandSame,
            nameProbableMatch,
            productLocaleName: productLocaleName || '---',
            currencyType: ct,
          };
        },
      ) || [];

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Name',
        dataIndex: ['warehouseProduct', 'name'],
        sorter: (a: DataType, b: DataType): number =>
          a.warehouseProduct.name.localeCompare(b.warehouseProduct.name),
        filterSearch: true,
        filters: dataSourceUE
          .map((data: DataType) => ({
            text: data.warehouseProduct.name,
            value: data.warehouseProduct.name,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
        onFilter: (value: string, record: DataType): boolean =>
          record.warehouseProduct.name.indexOf(value) === 0,
        render: (name: string, record: DataType): JSX.Element => {
          return (
            <Space size={8} align="center" direction="horizontal">
              <Typography.Text
                strong
                type={
                  record.nameProbableMatch >= nameProbableMatchThreshold
                    ? 'success'
                    : 'danger'
                }
              >
                {round(record.nameProbableMatch * 100, 2)}%
              </Typography.Text>

              <Space size={8} align="start" direction="vertical">
                <Link
                  to={`/downloader/warehouse-product/${record.warehouseProduct.uuid}`}
                  label={name}
                  openInNewTab
                />

                <Link
                  to={`/product/${record.product.uuid}`}
                  label={record.productLocaleName}
                  openInNewTab
                />
              </Space>
            </Space>
          );
        },
        width: '30%',
      },
      {
        title: 'MPN',
        dataIndex: ['warehouseProduct', 'mpn'],
        sorter: (a: DataType, b: DataType): number =>
          a.warehouseProduct.mpn.localeCompare(b.warehouseProduct.mpn),
        filterSearch: true,
        filters: dataSourceUE
          .map((data: DataType) => ({
            text: data.warehouseProduct.mpn,
            value: data.warehouseProduct.mpn,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
        onFilter: (value: string, record: DataType): boolean =>
          record.warehouseProduct.mpn.indexOf(value) === 0,
        render: (mpn: string, record: DataType): JSX.Element => {
          const productMpn: string = record.product.mpn;

          return (
            <Space size={8} align="center" direction="horizontal">
              <Typography.Text
                strong
                type={record.isMpnSame ? 'success' : 'danger'}
              >
                {record.isMpnSame ? (
                  <CheckCircleOutlined style={{ color: green.primary }} />
                ) : (
                  <CloseCircleOutlined style={{ color: red.primary }} />
                )}
              </Typography.Text>

              <Space size={8} align="start" direction="vertical">
                {mpn}

                {productMpn}
              </Space>
            </Space>
          );
        },
        width: '10%',
      },
      {
        title: 'Product code',
        dataIndex: ['warehouseProduct', 'productCode'],
        render: (productCode: string, record: DataType): JSX.Element =>
          renderProductCode(productCode, record.warehouseProduct.url),
        width: '10%',
      },
      {
        title: 'Brand',
        dataIndex: ['warehouseProduct', 'brand'],
        sorter: (a: DataType, b: DataType): number =>
          a.warehouseProduct.brand.localeCompare(b.warehouseProduct.brand),
        filterSearch: true,
        filters: dataSourceUE
          .map((data: DataType) => ({
            text: data.warehouseProduct.brand,
            value: data.warehouseProduct.brand,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
        onFilter: (value: string, record: DataType): boolean =>
          record.warehouseProduct.brand.indexOf(value) === 0,
        render: (brand: string, record: DataType): JSX.Element => {
          let type: BaseType = record.isBrandSame ? 'success' : 'danger';

          if (!enabledBrandCheck) {
            type = 'warning';
          }

          let checkIcon: JSX.Element = record.isBrandSame ? (
            <CheckCircleOutlined style={{ color: green.primary }} />
          ) : (
            <CloseCircleOutlined style={{ color: red.primary }} />
          );

          if (!enabledBrandCheck) {
            checkIcon = record.isBrandSame ? (
              <CheckCircleOutlined style={{ color: green.primary }} />
            ) : (
              <QuestionCircleOutlined style={{ color: yellow.primary }} />
            );
          }

          return (
            <Space size={8} align="center" direction="horizontal">
              <Typography.Text strong type={type}>
                {checkIcon}
              </Typography.Text>
              <Space size={8} align="start" direction="vertical">
                {brand}

                {record.product.brand}
              </Space>
            </Space>
          );
        },
        width: '5%',
      },
      {
        title: 'Product is active',
        dataIndex: ['product', 'isActive'],
        sorter: (a: DataType, b: DataType): number =>
          Number(a.product.isActive) - Number(b.product.isActive),
        filterSearch: true,
        filters: [
          {
            text: 'Active',
            value: 'active',
          },
          {
            text: 'Inactive',
            value: 'inactive',
          },
        ],
        onFilter: (value: boolean | Key, record: DataType): boolean => {
          if (value === 'active') {
            return record.product.isActive;
          }

          if (value === 'inactive') {
            return !record.product.isActive;
          }

          return false;
        },
        render: (isActive: boolean): JSX.Element =>
          isActive ? (
            <CheckCircleOutlined style={{ color: green.primary }} />
          ) : (
            <CloseCircleOutlined style={{ color: red.primary }} />
          ),
        width: '5%',
      },
      {
        title: 'Warehouse product count',
        dataIndex: ['warehouseProduct', 'count'],
        sorter: (a: DataType, b: DataType): number =>
          a.warehouseProduct.count - b.warehouseProduct.count,
        filterSearch: true,
        filters: [
          {
            text: 'In stock',
            value: 'inStock',
          },
          {
            text: 'Full stock',
            value: 'fullStock',
          },
          {
            text: 'Low stock',
            value: 'lowStock',
          },
          {
            text: 'Out of stock',
            value: 'outOfStock',
          },
        ],
        onFilter: (value: boolean | Key, record: DataType): boolean => {
          if (value === 'inStock') {
            return record.warehouseProduct.count > 0;
          }

          if (value === 'fullStock') {
            return record.warehouseProduct.count >= 5;
          }

          if (value === 'lowStock') {
            return (
              record.warehouseProduct.count > 0 &&
              record.warehouseProduct.count < 5
            );
          }

          if (value === 'outOfStock') {
            return record.warehouseProduct.count === 0;
          }

          return false;
        },
        render: (value: number): JSX.Element => {
          if (value >= 5) {
            return (
              <Typography.Text strong type="success">
                {value}
              </Typography.Text>
            );
          }
          if (value >= 2) {
            return (
              <Typography.Text strong type="warning">
                {value}
              </Typography.Text>
            );
          }

          return (
            <Typography.Text strong type="danger">
              {value}
            </Typography.Text>
          );
        },
        width: '5%',
      },
      {
        title: 'Warehouse product price',
        dataIndex: ['warehouseProduct', 'price'],
        sorter: (a: DataType, b: DataType): number =>
          a.warehouseProduct.price - b.warehouseProduct.price,
        render: (value: number, record: DataType): JSX.Element => {
          return (
            <Typography.Text strong>
              {formatCurrency(value, record.currencyType)}
            </Typography.Text>
          );
        },
        width: '5%',
      },
      {
        title: 'Actions',
        filterSearch: true,
        filters: [
          {
            text: 'Product is pairable',
            value: 'productIsPairable',
          },
          {
            text: 'Product is not pairable',
            value: 'productIsNotPairable',
          },
        ],
        onFilter: (value: boolean | Key, record: DataType): boolean => {
          if (value === 'productIsPairable') {
            const isPairable: boolean = productIsPairable(record);

            return isPairable;
          }

          if (value === 'productIsNotPairable') {
            const isPairable: boolean = productIsPairable(record);

            return !isPairable;
          }

          return false;
        },
        render(record: DataType): JSX.Element {
          const isPairable: boolean = productIsPairable(record);
          if (isPairable) {
            return (
              <Button
                type="primary"
                onClick={(): void => {
                  dispatch(
                    downloaderWarehouseProductPairWithExistingProduct(
                      record.product.uuid,
                      record.warehouseProduct.uuid,
                    ),
                  );
                }}
              >
                Pair with product
              </Button>
            );
          }

          return (
            <Popconfirm
              title="Are you sure to pair this product?"
              onConfirm={(): void => {
                dispatch(
                  downloaderWarehouseProductPairWithExistingProduct(
                    record.product.uuid,
                    record.warehouseProduct.uuid,
                  ),
                );
                dispatch(
                  downloaderSyncWarehouseProduct(
                    record.product.uuid,
                    record.warehouseProduct.uuid,
                  ),
                );
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger>
                Pair with product
              </Button>
            </Popconfirm>
          );
        },
        width: '10%',
      },
    ];

    setDataSource(dataSourceUE);
    setColumns(columnsUE);
  }, [
    Language.languageType,
    Report.warehouseProductsPairing,
    Report.updatedAt,
    Warehouse.warehouses,
    warehouseUuid,
    enabledBrandCheck,
    nameProbableMatchThreshold,
  ]);

  return (
    <>
      <Typography>
        <Typography.Title level={2}>
          Report warehouse products pairing
        </Typography.Title>
      </Typography>

      <Divider />

      <Space size={16} direction="vertical" style={{ width: '100%' }}>
        <Space size={16} align="center" split={<Divider type="vertical" />}>
          <Select
            style={{ width: 200 }}
            placeholder="Select warehouse"
            allowClear
            showSearch
            optionFilterProp="children"
            options={warehouseOptions}
            onChange={(value: string): void => {
              setWarehouseUuid(value);
            }}
          />

          <Tooltip title="Refresh">
            <Button
              type="primary"
              onClick={(): void => {
                if (warehouseUuid && Language.languageType) {
                  dispatch(reportWarehouseProductsPairingLoad(warehouseUuid));
                }
              }}
              disabled={warehouseUuid === null || Report.inProcess}
            >
              Refresh <ReloadOutlined />
            </Button>
          </Tooltip>

          <Typography>
            {Report.updatedAt &&
              `Updated at ${dayjs(Report.updatedAt).fromNow()}`}
          </Typography>

          <Switch
            checkedChildren="Brand check enabled"
            unCheckedChildren="Brand check disabled"
            checked={enabledBrandCheck}
            onChange={(checked: boolean): void => {
              setenabledBrandCheck(checked);
            }}
          />

          <Typography>
            <Space size={8} align="center">
              Name probable match threshold:
              <Select
                style={{ width: 100 }}
                defaultValue={nameProbableMatchThreshold}
                onChange={(value: number): void => {
                  setNameProbableMatchThreshold(value);
                }}
                options={[
                  { label: 'off', value: 0 },
                  { label: '5 %', value: 0.05 },
                  { label: '10 %', value: 0.1 },
                  { label: '15 %', value: 0.15 },
                  { label: '25 %', value: 0.25 },
                  { label: '50 %', value: 0.5 },
                  { label: '75 %', value: 0.75 },
                ]}
              />
            </Space>
          </Typography>
        </Space>
      </Space>

      <Divider />

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Report.inProcess}
      />
    </>
  );
}

const mapStateToProps = ({
  Report,
  Warehouse,
  Language,
}: RootState): {
  Report: IReportState;
  Warehouse: IWarehouseState;
  Language: ILanguageState;
} => ({
  Report,
  Warehouse,
  Language,
});

export default connect(mapStateToProps)(
  ReportWarehouseProductsPairingContainer,
);
