import { type IAdminCategory } from '@mahawi/eshop-common';
import { createAction } from '@reduxjs/toolkit';

import { CATEGORIES_ACTION } from './types';

export const categoryAdd = createAction(
  CATEGORIES_ACTION.ADD,
  (name: string, languageTypeCode: string, parentCategoryUuid?: string) => ({
    payload: {
      name,
      languageType: {
        code: languageTypeCode,
      },
      parentCategoryUuid,
    },
  }),
);

export const categoriesLoadAll = createAction(CATEGORIES_ACTION.LOAD_ALL);

export const categoriesLoadAllResponse = createAction(
  CATEGORIES_ACTION.LOAD_ALL_RESPONSE,
  (categories: IAdminCategory[]) => ({
    payload: { categories },
  }),
);

export const getLikelyProductCategories = createAction(
  CATEGORIES_ACTION.GET_LIKELY_PRODUCT_CATEGORIES,
  (uuids: string[]) => ({
    payload: { category: { uuids } },
  }),
);

export const getLikelyProductCategoriesResponse = createAction(
  CATEGORIES_ACTION.GET_LIKELY_PRODUCT_CATEGORIES_RESPONSE,
  (uuids: string[]) => ({
    payload: { category: { uuids } },
  }),
);
