import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import {
  EPropertyType,
  type IProductProperty,
  type IPropertiesProductSave,
} from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Space,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { type ILanguageState } from '../../reducers/language/types';
import { productPropertiesSave } from '../../reducers/product/actions';
import { type IProductState } from '../../reducers/product/types';
import { loadProductProperties } from '../../reducers/properties/actions';
import { type IPropertiesState } from '../../reducers/properties/types';
import Link from '../link';

const { Panel } = Collapse;
const { Text, Paragraph } = Typography;

interface IForm {
  productProperties: Record<string, IPropertiesProductSave>;
}

function ProductProperties({
  Product,
  Properties,
  Language,
  dispatch,
}: {
  Product: IProductState;
  Properties: IPropertiesState;
  Language: ILanguageState;
  dispatch: Dispatch;
}): JSX.Element {
  const [form] = Form.useForm<IForm>();

  const [initialValues, setInitialValues] = useState<IForm>({
    productProperties: {},
  });
  const [formElements, setFormElements] = useState<JSX.Element[]>([]);

  useEffect((): void => {
    dispatch(loadProductProperties(Product.uuid));
  }, [Product.uuid, dispatch]);

  useEffect((): void => {
    const productProperties: Record<string, IPropertiesProductSave> = {};

    Properties.product.properties?.forEach(
      (productProperty: IProductProperty): void => {
        productProperties[productProperty.code] = {
          value: productProperty.value,
          maxValue: productProperty.maxValue,
        };
      },
    );

    setInitialValues({
      productProperties,
    });
  }, [Properties.product.properties]);

  useEffect((): void => {
    const formElementsUE: JSX.Element[] =
      Properties.product.properties?.map(
        (property: IProductProperty): JSX.Element => {
          const { code, names, type, propertyValue, unit } = property;

          const propertyLocalizedName: string | null = getTranslation(
            names,
            Language.languageType,
          );

          const propertyName: string = propertyLocalizedName || `** ${code} **`;

          const propertyNameEl: JSX.Element = (
            <Col span={24}>
              <Space size={16} align="start">
                <Text>{propertyName}</Text>
                {unit && <Text type="secondary">{unit}</Text>}
                <Link to={`/property/${code}`} openInNewTab />
              </Space>
            </Col>
          );

          if (type === EPropertyType.ENUM && propertyValue) {
            const propertyValueName: string | null = getTranslation(
              propertyValue.names,
              Language.languageType,
            );

            return (
              <Row gutter={16} key={code}>
                {propertyNameEl}

                <Col span={24}>
                  <Paragraph>
                    {propertyValueName || 'N/A'}{' '}
                    <small>({propertyValue.code})</small>
                  </Paragraph>
                </Col>
              </Row>
            );
          }

          return (
            <Row gutter={16} key={code}>
              {propertyNameEl}

              <Col span={12}>
                <Form.Item name={['productProperties', code, 'value']}>
                  <Input placeholder="Value" disabled={Product.inProcess} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name={['productProperties', code, 'maxValue']}>
                  <Input
                    placeholder="Maximal value"
                    disabled={Product.inProcess}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        },
      ) || [];

    setFormElements(formElementsUE);
  }, [
    Language.languageType?.code,
    Properties.product.properties,
    Product.inProcess,
  ]);

  const onFinish = useCallback(
    ({ productProperties }: IForm): void => {
      dispatch(productPropertiesSave(Product.uuid, productProperties));
    },
    [dispatch, Product.uuid],
  );

  return (
    <Collapse>
      <Panel header="Properties" key="product-properties">
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={initialValues}
        >
          {formElements}

          <Row>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={Product.inProcess}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ Product, Language, Properties }: RootState) => ({
  Product,
  Language,
  Properties,
});

export default connect(mapStateToProps)(ProductProperties);
