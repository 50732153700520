import {
  type IAdminWarehouseProductList,
  type IProductFeatureFlag,
} from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import { Alert, Space, Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { featureFlagTags } from '../../fragments/feature-flag-tags';
import {
  getTableFeatureFlagsColumnFilters,
  getTableFeatureFlagsColumnOnFilter,
} from '../../fragments/table-feature-flags-column';
import { getColumnSearch } from '../../fragments/table-get-column-search';
import { renderProductCode } from '../../fragments/table-product-code';
import { type IDownloaderState } from '../../reducers/downloader/types';
import { type IFeatureFlagsState } from '../../reducers/feature-flags/types';
import Link from '../link';
import Table from '../table';

interface DataType extends IAdminWarehouseProductList {
  key: string;
}

const { Text } = Typography;

function WarehouseProductsTable({
  Downloader,
  FeatureFlags,
}: {
  Downloader: IDownloaderState;
  FeatureFlags;
}): JSX.Element {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);

  useEffect((): void => {
    const dataSourceUE: DataType[] = [];
    const warehousesName: Set<string> = new Set<string>([]);
    const brandsName: Set<string> = new Set<string>([]);

    Downloader.warehouse.products?.forEach(
      (product: IAdminWarehouseProductList): void => {
        warehousesName.add(product.warehouse.name);
        brandsName.add(product.brand);

        dataSourceUE.push({
          key: product.uuid,
          ...product,
        });
      },
    );

    setDataSource(dataSourceUE);

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Warehouse',
        dataIndex: 'warehouse',
        sorter: (a: DataType, b: DataType): number =>
          a.warehouse.name.localeCompare(b.warehouse.name),
        filterSearch: true,
        filters: [...warehousesName]
          .map((warehouse: string) => ({
            text: warehouse,
            value: warehouse,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
        onFilter: (value: string, record: DataType): boolean =>
          record.warehouse.name === value,
        render: (warehouse: IAdminWarehouseProductList): string =>
          warehouse.name,
        width: '10%',
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        sorter: (a: DataType, b: DataType): number =>
          a.brand.localeCompare(b.brand),
        filterSearch: true,
        filters: [...brandsName]
          .map((brand: string) => ({
            text: brand,
            value: brand,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
        onFilter: (value: string, record: DataType): boolean =>
          record.brand === value,
        width: '10%',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: DataType, b: DataType): number =>
          a.name.localeCompare(b.name),
        render: (name: string, record: DataType): JSX.Element => (
          <Link
            to={`/downloader/warehouse-product/${record.uuid}`}
            openInNewTab
            label={name}
          />
        ),
        width: '40%',
        ...getColumnSearch('name'),
      },
      {
        title: 'Price',
        dataIndex: 'price',
        sorter: {
          compare: (a: DataType, b: DataType): number => a.price - b.price,
          multiple: 100,
        },
        width: '10%',
      },
      {
        title: 'Stock',
        dataIndex: 'count',
        sorter: {
          compare: (a: DataType, b: DataType): number => a.count - b.count,
          multiple: 1,
        },
        filterSearch: true,
        filters: [
          {
            text: 'In stock',
            value: 'inStock',
          },
          {
            text: 'Full stock',
            value: 'fullStock',
          },
          {
            text: 'Low stock',
            value: 'lowStock',
          },
          {
            text: 'Out of stock',
            value: 'outOfStock',
          },
        ],
        onFilter: (value: string, record: DataType): boolean => {
          if (value === 'inStock') {
            return record.count > 0;
          }

          if (value === 'fullStock') {
            return record.count >= 5;
          }

          if (value === 'lowStock') {
            return record.count > 0 && record.count < 5;
          }

          if (value === 'outOfStock') {
            return record.count === 0;
          }

          return false;
        },
        render: (value: number): JSX.Element => {
          if (value >= 5) {
            return (
              <Text strong type="success">
                {value}
              </Text>
            );
          }
          if (value >= 2) {
            return (
              <Text strong type="warning">
                {value}
              </Text>
            );
          }

          return (
            <Text strong type="danger">
              {value}
            </Text>
          );
        },
        width: '5%',
      },
      {
        title: 'Product code',
        dataIndex: 'productCode',
        sorter: (a: DataType, b: DataType): number =>
          a.productCode.localeCompare(b.productCode),
        filterSearch: true,
        filters: [
          {
            text: 'With warehouse URL',
            value: 'withWarehouseUrl',
          },
          {
            text: 'Without warehouse URL',
            value: 'withoutWarehouseUrl',
          },
        ],
        onFilter: (value: string, record: DataType): boolean => {
          if (value === 'withWarehouseUrl') {
            return record.url !== undefined;
          }

          if (value === 'withoutWarehouseUrl') {
            return record.url === undefined;
          }

          return record.productCode === value;
        },
        render: (productCode: string, record: DataType): JSX.Element =>
          renderProductCode(productCode, record.url),
        width: '10%',
      },
      {
        title: 'MPN',
        dataIndex: 'mpn',
        sorter: (a: DataType, b: DataType): number =>
          a.mpn.localeCompare(b.mpn),
        filterSearch: true,
        onFilter: (value: string, record: DataType): boolean =>
          record.mpn === value,
        width: '5%',
      },
      {
        title: 'GTIN',
        dataIndex: 'gtin',
        sorter: (a: DataType, b: DataType): number =>
          a.gtin.localeCompare(b.gtin),
        filterSearch: true,
        onFilter: (value: string, record: DataType): boolean =>
          record.gtin === value,
        width: '5%',
      },
      {
        title: 'Feature flags',
        dataIndex: 'featureFlags',
        sorter: (a: DataType, b: DataType): number =>
          a.featureFlags.length - b.featureFlags.length,
        filterSearch: true,
        filters: getTableFeatureFlagsColumnFilters({
          dataSource: dataSourceUE,
          FeatureFlags,
        }),
        onFilter: getTableFeatureFlagsColumnOnFilter,
        render: (productFeatureFlags: IProductFeatureFlag[]): JSX.Element[] =>
          featureFlagTags({ productFeatureFlags, FeatureFlags }),
        width: '10%',
      },
      {
        title: 'Actions',
        sorter: {
          compare: (a: DataType, b: DataType): number =>
            a.product.uuid?.localeCompare(b.product.uuid || '') || 0,
          multiple: 2,
        },
        filters: [
          {
            text: 'Paired with product',
            value: 'pairedWithProduct',
          },
          {
            text: 'Not paired with product',
            value: 'notPairedWithProduct',
          },
        ],
        onFilter: (value: string, record: DataType): boolean => {
          if (value === 'pairedWithProduct') {
            return record.product.uuid !== undefined;
          }

          if (value === 'notPairedWithProduct') {
            return record.product.uuid === undefined;
          }

          return false;
        },
        render: (_text: string, record: DataType): JSX.Element => (
          <Space size={16} align="start">
            {record.product.uuid && (
              <Link to={`/product/${record.product.uuid}`} openInNewTab />
            )}
          </Space>
        ),
        width: '5%',
      },
    ];

    setColumns(columnsUE);
  }, [
    Downloader.warehouse.products,
    Downloader.updatedAt,
    FeatureFlags.featureFlags,
    FeatureFlags.updatedAt,
  ]);

  if (Downloader.warehouse.products === null) {
    return (
      <Alert
        message="Error"
        description="Warehouse products not found"
        type="error"
        showIcon
      />
    );
  }

  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Downloader.inProcess}
      />
    </Space>
  );
}

const mapStateToProps = ({
  Downloader,
  FeatureFlags,
}: RootState): {
  Downloader: IDownloaderState;
  FeatureFlags: IFeatureFlagsState;
} => ({ Downloader, FeatureFlags });

export default connect(mapStateToProps)(WarehouseProductsTable);
