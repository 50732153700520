import {
  EOrderStatus,
  EReviewStatusType,
  type IGetOrderResponse,
} from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum ORDER_ACTION {
  LOAD = 'ORDER_ACTION_LOAD',
  LOAD_RESPONSE = 'ORDER_ACTION_LOAD_RESPONSE',
  SAVE_ORDER_TAGS = 'ORDER_ACTION_SAVE_ORDER_TAGS',
  SAVE_ORDER_TAGS_RESPONSE = 'ORDER_ACTION_SAVE_ORDER_TAGS_RESPONSE',
  SENT_REVIEW_TO_CUSTOMER = 'ORDER_ACTION_SENT_REVIEW_TO_CUSTOMER',
  SENT_SHIPPING_TIME_TO_CUSTOMER = 'ORDER_ACTION_SENT_SHIPPING_TIME_TO_CUSTOMER',
  SET_ORDER_STATUS_CODE = 'ORDER_ACTION_SET_ORDER_STATUS_CODE',
  SET_ORDER_STATUS_CODE_RESPONSE = 'ORDER_ACTION_SET_ORDER_STATUS_CODE_RESPONSE',
  SET_REVIEW_STATUS_CODE = 'ORDER_ACTION_SET_REVIEW_STATUS_CODE',
  SET_REVIEW_STATUS_CODE_RESPONSE = 'ORDER_ACTION_SET_REVIEW_STATUS_CODE_RESPONSE',
}

export interface ILoad extends UnknownAction {
  type: typeof ORDER_ACTION.LOAD;
  payload: {
    order: {
      uuid: string;
    };
  };
}

export interface ILoadResponse extends UnknownAction {
  type: typeof ORDER_ACTION.LOAD_RESPONSE;
  payload: {
    order: IGetOrderResponse | null;
  };
}

export interface ILoadCallback {
  (arg0: ILoadResponse): void;
}

export interface ISaveOrderTags extends UnknownAction {
  type: typeof ORDER_ACTION.SAVE_ORDER_TAGS;
  payload: {
    order: {
      uuid: string;
    };
    tags: string[];
  };
}

export interface ISaveOrderTagsResponse extends UnknownAction {
  type: typeof ORDER_ACTION.SAVE_ORDER_TAGS_RESPONSE;
  payload: {
    order: IGetOrderResponse | null;
  };
}

export interface ISaveOrderTagsCallback {
  (arg0: ISaveOrderTagsResponse): void;
}

export interface ISentReviewToCustomer extends UnknownAction {
  type: typeof ORDER_ACTION.SENT_REVIEW_TO_CUSTOMER;
  payload: {
    order: {
      uuid: string;
    };
  };
}

export interface ISentShippingTimeToCustomer extends UnknownAction {
  type: typeof ORDER_ACTION.SENT_SHIPPING_TIME_TO_CUSTOMER;
  payload: {
    order: {
      uuid: string;
    };
    shippingTime: string;
  };
}

export interface ISetOrderStatusCode extends UnknownAction {
  type: typeof ORDER_ACTION.SET_ORDER_STATUS_CODE;
  payload: {
    order: {
      uuid: string;
      orderStatusCode: EOrderStatus;
    };
  };
}

export interface ISetOrderStatusCodeResponse extends UnknownAction {
  type: typeof ORDER_ACTION.SET_ORDER_STATUS_CODE_RESPONSE;
  payload: {
    order: IGetOrderResponse | null;
  };
}

export interface ISetOrderStatusCodeCallback {
  (arg0: ISetOrderStatusCodeResponse): void;
}

export interface ISetReviewStatusCode extends UnknownAction {
  type: typeof ORDER_ACTION.SET_REVIEW_STATUS_CODE;
  payload: {
    review: {
      uuid: string;
      reviewStatusCode: EReviewStatusType;
    };
  };
}

export interface ISetReviewStatusCodeResponse extends UnknownAction {
  type: typeof ORDER_ACTION.SET_REVIEW_STATUS_CODE_RESPONSE;
  paload: {
    order: IGetOrderResponse | null;
  };
}

export interface ISetReviewStatusCodeCallback {
  (arg0: ISetReviewStatusCodeResponse): void;
}

export interface IOrderState {
  inProcess: boolean;
  order?: IGetOrderResponse | null;
}
