import { EOrderStatus } from '@mahawi/eshop-common/dist/src/types';
import React from 'react';

import OrderLayout from '../../components/orders/order-layout';

function OrdersDeliveredContainer(): JSX.Element {
  return <OrderLayout orderStatusTypeCodes={[EOrderStatus.DELIVERED]} />;
}

export default OrdersDeliveredContainer;
