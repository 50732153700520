import { ReloadOutlined } from '@ant-design/icons';
import { EOrderStatus } from '@mahawi/eshop-common/dist/src/types';
import { Button, Divider, InputNumber, Space } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import OrdersTable from '../../components/orders/orders-table';
import { renderUpdatedAtMessage } from '../../fragments/updated-at-message';
import { type RootState } from '../../reducers';
import {
  ordersLoadByOrderStatusCodes,
  ordersReset,
} from '../../reducers/orders/actions';
import { type IOrdersState } from '../../reducers/orders/types';
import { type ITagsState } from '../../reducers/tags/types';

function OrderLayout({
  orderStatusTypeCodes,
  dispatch,
  Orders,
  Tags,
}: {
  orderStatusTypeCodes: EOrderStatus[];
  dispatch: Dispatch;
  Orders: IOrdersState;
  Tags: ITagsState;
}): JSX.Element {
  const [ordersLimit, setOrdersLimit] = React.useState(100);

  useEffect((): void => {
    dispatch(ordersReset());
  }, []);

  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      <Space size={16} align="center" split={<Divider type="vertical" />}>
        <InputNumber
          type="number"
          addonBefore="Limit"
          value={ordersLimit}
          min={1}
          defaultValue={100}
          onChange={(limit: number | null): void =>
            setOrdersLimit(Number(limit))
          }
        />

        <Button
          type={!Orders.orders ? 'primary' : 'default'}
          onClick={(): void => {
            dispatch(
              ordersLoadByOrderStatusCodes(orderStatusTypeCodes, ordersLimit),
            );
          }}
          disabled={Orders.inProcess}
        >
          Reload all <ReloadOutlined />
        </Button>

        {renderUpdatedAtMessage(
          Orders.updatedAt,
          Orders.inProcess,
          (): boolean => Orders.orders !== undefined,
        )}
      </Space>

      <OrdersTable Orders={Orders} Tags={Tags} />
    </Space>
  );
}

const mapStateToProps = ({
  Orders,
  Tags,
}: RootState): { Orders: IOrdersState; Tags: ITagsState } => ({
  Orders,
  Tags,
});

export default connect(mapStateToProps)(OrderLayout);
