import { EditOutlined } from '@ant-design/icons';
import { type RootState } from 'admin/react/reducers';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { downloaderAddWarehouseProduct } from '../../reducers/downloader/actions';
import { type IDownloaderState } from '../../reducers/downloader/types';

interface IForm {
  name: string;
}

function DownloaderAddWarehouseProduct({
  dispatch,
  Downloader,
  name,
}: {
  dispatch: Dispatch;
  Downloader: IDownloaderState;
  name: string;
}): JSX.Element {
  const [form] = Form.useForm<IForm>();

  const onFinish = useCallback(
    (values: IForm): void => {
      if (!Downloader.warehouse.product?.uuid) {
        return;
      }

      dispatch(
        downloaderAddWarehouseProduct(
          Downloader.warehouse.product.uuid,
          values.name,
        ),
      );
    },
    [Downloader.warehouse.product?.uuid, dispatch],
  );

  if (!Downloader.warehouse.product) {
    return <>---</>;
  }

  return (
    <Form
      form={form}
      onFinish={(values: IForm): void => onFinish(values)}
      initialValues={{ name }}
    >
      <Row gutter={[0, 24]}>
        <Col span={14} offset={5} className="gutter-row">
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
              },
              {
                type: 'string',
                min: 3,
              },
            ]}
          >
            <Space.Compact style={{ width: '100%' }}>
              <Input
                size="large"
                prefix={<EditOutlined />}
                type="string"
                disabled={Downloader.inProcess}
                addonBefore="Product name"
                defaultValue={name}
              />
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={Downloader.inProcess}
              >
                Add
              </Button>
            </Space.Compact>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

const mapStateToProps = ({ Downloader }: RootState) => ({ Downloader });

export default connect(mapStateToProps)(DownloaderAddWarehouseProduct);
