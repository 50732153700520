import { Table } from 'antd';
import { type ColumnGroupType, type ColumnType } from 'antd/es/table';
import React from 'react';
import { useSearchParams } from 'react-router';

function TableComponent({
  columns,
  dataSource,
  isLoading,
}: {
  columns: (ColumnGroupType<object> | ColumnType<object>)[];
  dataSource: readonly object[];
  isLoading: boolean;
}): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={{
        position: ['topCenter', 'bottomCenter'],
        defaultCurrent: Number(searchParams.get('page')) || 1,
        defaultPageSize: Number(searchParams.get('pageSize')) || 10,
        onChange: (page, pageSize) => {
          searchParams.set('page', page.toString());
          searchParams.set('pageSize', pageSize.toString());

          setSearchParams(searchParams);
        },
      }}
      loading={isLoading}
    />
  );
}

export default TableComponent;
