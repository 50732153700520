import { EOrderStatus } from '@mahawi/eshop-common/dist/src/types';
import React from 'react';

import OrderLayout from '../../components/orders/order-layout';

function OrdersCreatedContainer(): JSX.Element {
  return <OrderLayout orderStatusTypeCodes={[EOrderStatus.CREATED]} />;
}

export default OrdersCreatedContainer;
