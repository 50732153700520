import { Layout } from 'antd';
import React, { type CSSProperties } from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router';

import SidebarDownloader from '../components/sidebar-downloader';
import SidebarEbay from '../components/sidebar-ebay';
import SidebarOffer from '../components/sidebar-offer';
import SidebarOrders from '../components/sidebar-orders';
import SidebarReport from '../components/sidebar-report';
import SidebarSettings from '../components/sidebar-settings';
import SiderContentComponent from '../components/sider-content';
import Topbar from '../components/topbar';
import PrivateRoute from '../private-route';
import { type RootState } from '../reducers';
import { type IUserState } from '../reducers/user/types';
import type Socket from '../socket';
import BrandContainer from './brand-container';
import CategoriesContainer from './categories-container';
import CategoryContainer from './category-container';
import DashboardContainer from './dashboard-container';
import DiscountContainer from './discount-container';
import DownloaderContainer from './downloader-container';
import DownloaderProductContainer from './downloader-product-container';
import DownloaderProductsContainer from './downloader-products-container';
import DownloaderWarehouseCategories from './downloader-warehouse-categories';
import DownloaderWarehouseCategory from './downloader-warehouse-category';
import DownloaderWarehouseProduct from './downloader-warehouse-product';
import DownloaderWarehouseProducts from './downloader-warehouse-products';
import DownloaderWarehouseProperties from './downloader-warehouse-properties';
import DownloaderWarehouseProperty from './downloader-warehouse-property';
import EbayContainer from './ebay-container';
import EbayMarketplaceContainer from './ebay-marketplace-container';
import EbayMarketplacesContainer from './ebay-marketplaces-container';
import HomeContainer from './home-container';
import LoginContainer from './login-container';
import LogoutContainer from './logout-container';
import MessengerContainer from './messenger-container';
import OfferContainer from './offer-container';
import OrderContainer from './order-container';
import OrdersAllContainer from './orders/orders-all-container';
import OrdersCancelledContainer from './orders/orders-cancelled-container';
import OrdersCreatedContainer from './orders/orders-created-container';
import OrdersDeliveredContainer from './orders/orders-delivered-container';
import OrdersPaidContainer from './orders/orders-paid-container';
import OrdersRefundedContainer from './orders/orders-refunded-container';
import OrdersShippedContainer from './orders/orders-shipped-container';
import OrdersContainer from './orders-container';
import ProductContainer from './product-container';
import ProductsContainer from './products-container';
import PropertiesContainer from './properties-container';
import PropertyContainer from './property-container';
import ReportContainer from './report-container';
import ReportProductLanguagesContainer from './report-product-languages-container';
import ReportPropertyLanguagesContainer from './report-property-languages-container';
import ReportPropertyValueLanguagesContainer from './report-property-value-languages-container';
import ReportWarehouseProductToImportContainer from './report-warehouse-product-to-import-container';
import ReportWarehouseProductTrendContainer from './report-warehouse-product-trend-container';
import ReportWarehouseProductsPairingContainer from './report-warehouse-products-pairing-container';
import SearchContainer from './search-container';
import SettingsContainer from './settings-container';
import SettingsCountriesContainer from './settings-countries-container';
import SettingsCountryContainer from './settings-country-container';
import SettingsTagsContainer from './settings-tags-container';

const { Header, Footer } = Layout;

const styleLayout: CSSProperties = {
  minHeight: '100vh',
};

const styleFooter: CSSProperties = {
  textAlign: 'center',
};

function LayoutContainer({
  User,
  socket,
}: {
  User: IUserState;
  socket?: Socket;
}): JSX.Element {
  return (
    <Layout style={styleLayout}>
      <Header>
        <Topbar />
      </Header>
      <Routes>
        <Route
          path="/"
          element={
            <SiderContentComponent contentChildren={<HomeContainer />} />
          }
        />
        <Route
          path="/login"
          element={
            <SiderContentComponent contentChildren={<LoginContainer />} />
          }
        />
        <Route
          path="/logout"
          element={
            <SiderContentComponent contentChildren={<LogoutContainer />} />
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent contentChildren={<DashboardContainer />} />
            </PrivateRoute>
          }
        />

        <Route
          path="/offer"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOffer />}
                contentChildren={<OfferContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/order/:orderUUID"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOrders />}
                contentChildren={<OrderContainer />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOrders />}
                contentChildren={<OrdersContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/orders/all"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOrders />}
                contentChildren={<OrdersAllContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/orders/cancelled"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOrders />}
                contentChildren={<OrdersCancelledContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/orders/created"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOrders />}
                contentChildren={<OrdersCreatedContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/orders/paid"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOrders />}
                contentChildren={<OrdersPaidContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/orders/refunded"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOrders />}
                contentChildren={<OrdersRefundedContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/orders/shipped"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOrders />}
                contentChildren={<OrdersShippedContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/orders/delivered"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOrders />}
                contentChildren={<OrdersDeliveredContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/brand"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOffer />}
                contentChildren={<BrandContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/categories"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOffer />}
                contentChildren={<CategoriesContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/category/:categoryUUID"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOffer />}
                contentChildren={<CategoryContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/properties"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOffer />}
                contentChildren={<PropertiesContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/property/:propertyCode"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOffer />}
                contentChildren={<PropertyContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/products"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOffer />}
                contentChildren={<ProductsContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/product/:productUUID"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarOffer />}
                contentChildren={<ProductContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/messenger"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                contentChildren={<MessengerContainer socket={socket} />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/discount"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent contentChildren={<DiscountContainer />} />
            </PrivateRoute>
          }
        />

        <Route
          path="/downloader"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarDownloader />}
                contentChildren={<DownloaderContainer />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/downloader/products"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarDownloader />}
                contentChildren={<DownloaderProductsContainer />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/downloader/product/:productDownloadedUUID"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarDownloader />}
                contentChildren={<DownloaderProductContainer />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/downloader/warehouse-categories"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarDownloader />}
                contentChildren={<DownloaderWarehouseCategories />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/downloader/warehouse-category/:uuid"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarDownloader />}
                contentChildren={<DownloaderWarehouseCategory />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/downloader/warehouse-properties"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarDownloader />}
                contentChildren={<DownloaderWarehouseProperties />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/downloader/warehouse-property/:rawName"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarDownloader />}
                contentChildren={<DownloaderWarehouseProperty />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/downloader/warehouse-products"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarDownloader />}
                contentChildren={<DownloaderWarehouseProducts />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/downloader/warehouse-product/:uuid"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarDownloader />}
                contentChildren={<DownloaderWarehouseProduct />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/ebay"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarEbay />}
                contentChildren={<EbayContainer />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/ebay/marketplaces"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarEbay />}
                contentChildren={<EbayMarketplacesContainer />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/ebay/marketplace/:code"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarEbay />}
                contentChildren={<EbayMarketplaceContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/report"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarReport />}
                contentChildren={<ReportContainer />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/report/product-languages"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarReport />}
                contentChildren={<ReportProductLanguagesContainer />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/report/property-languages"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarReport />}
                contentChildren={<ReportPropertyLanguagesContainer />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/report/property-value-languages"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarReport />}
                contentChildren={<ReportPropertyValueLanguagesContainer />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/report/warehouse-product-to-import"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarReport />}
                contentChildren={<ReportWarehouseProductToImportContainer />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/report/warehouse-product-trend"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarReport />}
                contentChildren={<ReportWarehouseProductTrendContainer />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/report/warehouse-products-pairing"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarReport />}
                contentChildren={<ReportWarehouseProductsPairingContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/search"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent contentChildren={<SearchContainer />} />
            </PrivateRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarSettings />}
                contentChildren={<SettingsContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/countries"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarSettings />}
                contentChildren={<SettingsCountriesContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/country/:isoCode"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarSettings />}
                contentChildren={<SettingsCountryContainer />}
              />
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/tags"
          element={
            <PrivateRoute isLoggedIn={User.isLoggedIn}>
              <SiderContentComponent
                siderChildren={<SidebarSettings />}
                contentChildren={<SettingsTagsContainer />}
              />
            </PrivateRoute>
          }
        />
      </Routes>
      <Footer style={styleFooter}>wawahi s.r.o.</Footer>
    </Layout>
  );
}

const mapStateToProps = ({ User }: RootState) => ({
  User,
});

export default connect(mapStateToProps)(LayoutContainer);
