import { type IAdminCategory } from '@mahawi/eshop-common';
import { createReducer } from '@reduxjs/toolkit';

import {
  categoriesLoadAll,
  categoriesLoadAllResponse,
  categoryAdd,
  getLikelyProductCategories,
  getLikelyProductCategoriesResponse,
} from './actions';
import { type ICategoriesState } from './types';

export const preloadedState: ICategoriesState = {
  inProcess: false,
  categories: undefined,
  updatedAt: undefined,
  likelyProductCategories: {
    uuids: undefined,
  },
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(categoryAdd, (state) => {
    state.inProcess = true;
  });

  builder.addCase(categoriesLoadAll, (state) => {
    state.inProcess = true;
  });

  builder.addCase(categoriesLoadAllResponse, (state, action) => {
    state.categories = action.payload.categories.sort(
      (a: IAdminCategory, b: IAdminCategory): number => a.position - b.position,
    );
    state.updatedAt = Date.now();
    state.inProcess = false;
  });

  builder.addCase(getLikelyProductCategories, (state) => {
    state.inProcess = true;
    state.likelyProductCategories.uuids = undefined;
  });

  builder.addCase(getLikelyProductCategoriesResponse, (state, action) => {
    state.likelyProductCategories.uuids = action.payload.category.uuids;
    state.inProcess = false;
    state.updatedAt = Date.now();
  });
});
