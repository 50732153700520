import { green, red } from '@ant-design/colors';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  GlobalOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import {
  EFeatureFlag,
  type IAdminWarehouse,
  type IProductFeatureFlag,
  type IProductWarehouse,
  type IWarehouseDetail,
} from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Popover,
  Row,
  Space,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import dayjs, { type Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { featureFlagTags } from '../../fragments/feature-flag-tags';
import {
  downloaderSyncWarehouseProduct,
  downloaderUnassignWarehouseProduct,
} from '../../reducers/downloader/actions';
import { type IFeatureFlagsState } from '../../reducers/feature-flags/types';
import { stockUpdate } from '../../reducers/product/actions';
import { type IProductState } from '../../reducers/product/types';
import { type IWarehouseState } from '../../reducers/warehouse/types';
import Link from '../link';

interface IForm {
  count: { [key: string]: number };
  priceEUR: { [key: string]: number };
  recommendedPriceEUR: { [key: string]: number };
  preOrderDays: { [key: string]: number };
  url: { [key: string]: string | undefined };
  isSyncActive: { [key: string]: boolean };
  syncCount: { [key: string]: boolean };
  syncPrice: { [key: string]: boolean };
  nextDeliveryDate: Dayjs | null;
}

const { Panel } = Collapse;

function Stock({
  Product,
  dispatch,
  Warehouse,
  FeatureFlags,
}: {
  Product: IProductState;
  dispatch: Dispatch;
  Warehouse: IWarehouseState;
  FeatureFlags: IFeatureFlagsState;
}): JSX.Element {
  const [form] = Form.useForm<IForm>();

  const [initialValues, setInitialValues] = useState({});
  const [inStockCount, setInStockCount] = useState<number>(0);
  const [warehouseElements, setWarehouseElements] = useState<JSX.Element[]>([]);
  const [showOnlyActiveWarehouses, setShowOnlyActiveWarehouses] =
    useState<boolean>(true);

  useEffect((): void => {
    const initialValuesUE: IForm = {
      count: {},
      priceEUR: {},
      recommendedPriceEUR: {},
      preOrderDays: {},
      url: {},
      isSyncActive: {},
      syncCount: {},
      syncPrice: {},
      nextDeliveryDate: Product.nextDeliveryDate
        ? dayjs(Product.nextDeliveryDate)
        : null,
    };

    Warehouse.warehouses?.forEach(({ uuid }: IWarehouseDetail): void => {
      initialValuesUE.count[uuid] = 0;
      initialValuesUE.priceEUR[uuid] = 0;
      initialValuesUE.recommendedPriceEUR[uuid] = 0;
      initialValuesUE.isSyncActive[uuid] = false;
      initialValuesUE.preOrderDays[uuid] = 0;
      initialValuesUE.syncCount[uuid] = false;
      initialValuesUE.syncPrice[uuid] = false;
      initialValuesUE.url[uuid] = '';
    });

    Product.warehouses.forEach(
      ({
        uuid,
        count,
        pricesEUR,
        preOrderDays,
        url,
        isSyncActive,
        syncPrice,
        syncCount,
      }: IProductWarehouse): void => {
        initialValuesUE.count[uuid] = count;
        initialValuesUE.priceEUR[uuid] = pricesEUR.purchase;
        initialValuesUE.recommendedPriceEUR[uuid] = pricesEUR.recommended;
        initialValuesUE.isSyncActive[uuid] = isSyncActive;
        initialValuesUE.preOrderDays[uuid] = preOrderDays;
        initialValuesUE.syncCount[uuid] = syncCount;
        initialValuesUE.syncPrice[uuid] = syncPrice;
        initialValuesUE.url[uuid] = url;
      },
    );

    const whPrimary: IProductWarehouse | undefined = Product.warehouses.find(
      (wh: IProductWarehouse): boolean => wh.warehouse.isPrimary,
    );

    setInitialValues(initialValuesUE);
    setInStockCount(whPrimary?.count || 0);
  }, [Product, Warehouse.warehouses, Product.updatedAt]);

  useEffect((): void => {
    const warehouseElementsUE: JSX.Element[] =
      Warehouse.warehouses
        ?.map((warehouse: IWarehouseDetail): JSX.Element | undefined => {
          if (!warehouse.isActive && showOnlyActiveWarehouses) {
            return undefined;
          }

          let isWarehousePrimary: boolean = false;

          const productWarehouseFeatureFlags: IProductFeatureFlag[] =
            Product.featureFlags.filter(
              (ff: IProductFeatureFlag): boolean =>
                [EFeatureFlag.WAREHOUSE_PRODUCT_CHECK_SYNC].includes(ff.code) &&
                ff.value?.warehouse?.uuid === warehouse.uuid,
            );

          const productWarehouseFeatureFlagsEls: JSX.Element[] =
            featureFlagTags({
              productFeatureFlags: productWarehouseFeatureFlags,
              FeatureFlags,
            });

          const productWarehouse: IProductWarehouse | undefined =
            Product.warehouses.find(
              (w: IProductWarehouse): boolean => w.uuid === warehouse.uuid,
            );

          let warehouseProductUrl: string | undefined;

          if (productWarehouse) {
            warehouseProductUrl = productWarehouse.url;

            isWarehousePrimary = productWarehouse.warehouse.isPrimary;
          }

          form.setFieldValue(
            ['count', warehouse.uuid],
            productWarehouse?.count || 0,
          );

          form.setFieldValue(
            ['priceEUR', warehouse.uuid],
            productWarehouse?.pricesEUR.purchase || 0,
          );

          form.setFieldValue(
            ['recommendedPriceEUR', warehouse.uuid],
            productWarehouse?.pricesEUR.recommended || 0,
          );

          form.setFieldValue(
            ['preOrderDays', warehouse.uuid],
            productWarehouse?.preOrderDays || 0,
          );

          form.setFieldValue(
            ['url', warehouse.uuid],
            productWarehouse?.url || '',
          );

          form.setFieldValue(
            ['isSyncActive', warehouse.uuid],
            productWarehouse?.isSyncActive || false,
          );

          form.setFieldValue(
            ['syncCount', warehouse.uuid],
            productWarehouse?.syncCount || false,
          );

          form.setFieldValue(
            ['syncPrice', warehouse.uuid],
            productWarehouse?.syncPrice || false,
          );

          return (
            <Row gutter={16} key={warehouse.uuid}>
              <Divider orientation="left">
                <Space size={8} align="start" direction="horizontal">
                  <>
                    <Typography.Paragraph>
                      {warehouse.name}
                    </Typography.Paragraph>
                    <small>{warehouse.internalDescription}</small>
                    {productWarehouseFeatureFlagsEls}
                  </>
                </Space>
              </Divider>

              <Col span={1}>
                {isWarehousePrimary ? <Tag color="blue">Primary</Tag> : null}
              </Col>

              <Col span={2}>
                <Form.Item name={['count', warehouse.uuid]}>
                  <InputNumber addonAfter="pcs" disabled={Product.inProcess} />
                </Form.Item>
              </Col>

              <Col span={2}>
                <Form.Item name={['priceEUR', warehouse.uuid]}>
                  <InputNumber addonAfter="€" disabled />
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item name={['recommendedPriceEUR', warehouse.uuid]}>
                  <InputNumber addonAfter="€" disabled />
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item name={['preOrderDays', warehouse.uuid]}>
                  <InputNumber disabled={Product.inProcess} />
                </Form.Item>
              </Col>

              {!warehouse.isInternal && (
                <>
                  <Col span={1}>
                    <Popover title="Open warehouse product page">
                      <Link
                        to={`/downloader/warehouse-product/${productWarehouse?.warehouseProduct.uuid}`}
                        openInNewTab
                        disabled={
                          Product.inProcess ||
                          !productWarehouse?.warehouseProduct.uuid
                        }
                      />
                    </Popover>
                  </Col>

                  <Col span={6}>
                    <Form.Item name={['url', warehouse.uuid]}>
                      <Input
                        addonBefore="URL"
                        addonAfter={
                          warehouseProductUrl ? (
                            <a
                              href={warehouseProductUrl}
                              target="_blank"
                              rel="noreferrer noopener nofollow"
                              aria-label="Open external source URL in new tab"
                            >
                              <GlobalOutlined />
                            </a>
                          ) : (
                            <GlobalOutlined disabled />
                          )
                        }
                        disabled={Product.inProcess}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={1}>
                    <Popover title="Is sync with external source active?">
                      <Form.Item
                        name={['isSyncActive', warehouse.uuid]}
                        valuePropName={
                          productWarehouse && productWarehouse.isSyncActive
                            ? 'checked'
                            : ''
                        }
                      >
                        <Switch />
                      </Form.Item>
                    </Popover>
                  </Col>

                  <Col span={1}>
                    <Popover title="Sync count with external source?">
                      <Form.Item
                        name={['syncCount', warehouse.uuid]}
                        valuePropName={
                          productWarehouse && productWarehouse.syncCount
                            ? 'checked'
                            : ''
                        }
                      >
                        <Switch />
                      </Form.Item>
                    </Popover>
                  </Col>

                  <Col span={1}>
                    <Popover title="Sync price with external source?">
                      <Form.Item
                        name={['syncPrice', warehouse.uuid]}
                        valuePropName={
                          productWarehouse && productWarehouse.syncPrice
                            ? 'checked'
                            : ''
                        }
                      >
                        <Switch />
                      </Form.Item>
                    </Popover>
                  </Col>

                  <Col span={1}>
                    <Tooltip title="Sync product with warehouse">
                      <Button
                        color="primary"
                        onClick={(): void => {
                          if (
                            !productWarehouse ||
                            !productWarehouse.warehouseProduct.uuid
                          ) {
                            return;
                          }

                          dispatch(
                            downloaderSyncWarehouseProduct(
                              Product.uuid,
                              productWarehouse.warehouseProduct.uuid,
                            ),
                          );
                        }}
                        disabled={Product.inProcess || !productWarehouse}
                      >
                        Sync <ReloadOutlined />
                      </Button>
                    </Tooltip>
                  </Col>

                  <Col span={1}>
                    <Popconfirm
                      title="Unassign warehouse product?"
                      okText="Yes"
                      cancelText="No"
                      description="Product will be unassigned from warehouse product."
                      onConfirm={(): void => {
                        if (!productWarehouse) {
                          return;
                        }

                        dispatch(
                          downloaderUnassignWarehouseProduct(
                            Product.uuid,

                            warehouse.uuid,
                            productWarehouse.warehouseProduct.uuid,
                          ),
                        );
                      }}
                    >
                      <Button
                        color="danger"
                        variant="outlined"
                        disabled={Product.inProcess || !productWarehouse}
                      >
                        Unassign <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  </Col>
                </>
              )}
            </Row>
          );
        })
        .filter(notEmpty) || [];

    setWarehouseElements(warehouseElementsUE);
  }, [
    Product.inProcess,
    Product.warehouses,
    Warehouse.warehouses,
    showOnlyActiveWarehouses,
    Product.updatedAt,
    FeatureFlags.featureFlags,
    Product.featureFlags,
  ]);

  const onFinish = useCallback(
    (
      {
        count,
        isSyncActive,
        nextDeliveryDate,
        preOrderDays,
        syncCount,
        syncPrice,
        url,
      }: IForm,
      warehouses: IWarehouseDetail[] | undefined,
      productUUID: string,
      productMPN: string | undefined,
    ): void => {
      if (!warehouses) {
        throw new Error('Warehouses not found!');
      }

      const warehousesFormatted: IAdminWarehouse[] = warehouses.map(
        (item: IWarehouseDetail): IAdminWarehouse => ({
          uuid: item.uuid,
          url: '',
          count: 0,
          pricesEUR: {
            purchase: 0,
            recommended: 0,
          },
          preOrderDays: 0,
          isSyncActive: false,
          syncCount: false,
          syncPrice: false,
        }),
      );

      Object.keys(count).map((uuid: string): string => {
        const wf: IAdminWarehouse | undefined = warehousesFormatted.find(
          (w: IAdminWarehouse): boolean => w.uuid === uuid,
        );

        if (!wf) {
          throw new Error('Warehouse formatted not found!');
        }

        const u: string | undefined = url[uuid];
        const c: number = count[uuid];
        const i: boolean = isSyncActive[uuid];
        const p: number = preOrderDays[uuid];
        const sp: boolean = syncPrice[uuid];
        const sc: boolean = syncCount[uuid];

        wf.url = u?.trim() || '';
        wf.count = c;
        wf.isSyncActive = [true, 'checked'].includes(i);
        wf.preOrderDays = p;
        wf.syncPrice = sp;
        wf.syncCount = sc;

        return uuid;
      });

      dispatch(
        stockUpdate(
          productUUID,
          productMPN,
          nextDeliveryDate ? nextDeliveryDate.toDate() : undefined,
          warehousesFormatted,
        ),
      );
    },
    [dispatch],
  );

  let statusIcon: JSX.Element = (
    <CloseCircleOutlined style={{ color: red.primary }} />
  );

  if (inStockCount) {
    statusIcon = <CheckCircleOutlined style={{ color: green.primary }} />;
  }

  return (
    <Collapse>
      <Panel
        header={
          <Space size={8} align="start" direction="horizontal">
            <strong>Stock ({inStockCount})</strong>
            {statusIcon}
          </Space>
        }
        key="stock"
      >
        <Row gutter={16}>
          <Switch
            checkedChildren="All warehouses"
            unCheckedChildren="Active warehouses"
            onChange={(): void =>
              setShowOnlyActiveWarehouses(!showOnlyActiveWarehouses)
            }
            value={!showOnlyActiveWarehouses}
          />
        </Row>

        <Row gutter={16}>
          <Col span={1}></Col>
          <Col span={2}>Count</Col>
          <Col span={2}>Purchase price EUR without VAT</Col>
          <Col span={3}>Recommended price EUR without VAT</Col>
          <Col span={3}>Preorder days</Col>
          <Col span={1}>Link</Col>
          <Col span={6}>URL</Col>
          <Col span={1}>Sync WH</Col>
          <Col span={1}>Sync count</Col>
          <Col span={1}>Sync price</Col>
          <Col span={1}>Sync WH product</Col>
        </Row>

        <Form
          form={form}
          onFinish={(values: IForm): void => {
            onFinish(values, Warehouse.warehouses, Product.uuid, Product.mpn);
          }}
          layout="vertical"
          initialValues={initialValues}
        >
          {warehouseElements}

          <Row gutter={16}>
            <Col span={6}>
              <Form.Item name="nextDeliveryDate">
                <DatePicker
                  placeholder="Next delivery date"
                  disabled={Product.inProcess}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={Product.inProcess}
                block
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ Product, Warehouse, FeatureFlags }: RootState) => ({
  Product,
  Warehouse,
  FeatureFlags,
});

export default connect(mapStateToProps)(Stock);
