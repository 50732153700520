import { ReloadOutlined } from '@ant-design/icons';
import {
  type IReportWarehouseProduct,
  type IReportWarehouseProductTrend,
  type IWarehouseDetail,
} from '@mahawi/eshop-common';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import {
  Button,
  Col,
  Divider,
  Input,
  Popover,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from 'antd';
import { type DefaultOptionType } from 'antd/es/select';
import { type ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { type ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import Link from '../components/link';
import Table from '../components/table';
import { type RootState } from '../reducers';
import { reportWarehouseProductTrendLoad } from '../reducers/report/actions';
import { type IReportState } from '../reducers/report/types';
import { type IWarehouseState } from '../reducers/warehouse/types';

const { Title } = Typography;

interface CountPrice {
  count: number;
  price: number;
  deltaCount: number;
  deltaPrice: number;
}

interface DataType {
  key: string;
  warehouseProductUuid: string;
  name: string;
  [key: string]: CountPrice | string;
}

const getColorByCount = (count: number | undefined): string => {
  if (!count) {
    return 'warning';
  }

  if (count < 0) {
    return 'success';
  }

  return 'error';
};

function ReportWarehouseProductTrendContainer({
  dispatch,
  Report,
  Warehouse,
}: {
  dispatch: Dispatch;
  Report: IReportState;
  Warehouse: IWarehouseState;
}): JSX.Element {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);
  const [dayInterval, setDayInterval] = useState<number>(3);
  const [warehouseProductsCount, setwarehouseProductsCount]: [
    number,
    React.Dispatch<React.SetStateAction<number>>,
  ] = useState<number>(100);
  const [excludeAssignedProducts, setExcludeAssignedProducts]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = useState<boolean>(true);

  const [warehouseOptions, setWarehouseOptions] = useState<DefaultOptionType[]>(
    [],
  );
  const [warehouseUuid, setWarehouseUuid] = useState<string | null>(null);
  const [minimalWeight, setMinimalWeight] = useState<number>(0.001);
  const [maximalWeight, setMaximalWeight] = useState<number>(30);
  const [minimalPrice, setMinimalPrice] = useState<number>(0);
  const [maximalPrice, setMaximalPrice] = useState<number>(60000);
  const [scenario, setScenario] = useState<string>('SELL_COUNT');

  useEffect((): void => {
    const warehouseOptionsUE: DefaultOptionType[] =
      Warehouse.warehouses
        ?.filter((warehouse: IWarehouseDetail): boolean => warehouse.isActive)
        .map(
          (warehouse: IWarehouseDetail): DefaultOptionType => ({
            label: warehouse.name,
            value: warehouse.uuid,
          }),
        ) || [];

    setWarehouseOptions(warehouseOptionsUE);
  }, [Warehouse.warehouses, Warehouse.updatedAt]);

  useEffect((): void => {
    if (!Report.warehouseProductTrend) {
      return;
    }

    const { dateFrom, dateTo, report } = Report.warehouseProductTrend;
    const dateColumnsCount: number = dayjs(dateTo).diff(dayjs(dateFrom), 'day');

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Warehuse product UUID',
        dataIndex: 'warehouseProductUuid',
        render: (_text, record: DataType): JSX.Element => (
          <Link
            to={`/downloader/warehouse-product/${record.warehouseProductUuid}`}
            openInNewTab
            label={record.name}
          />
        ),
      },
    ];

    Array.from({ length: dateColumnsCount }, (_, i: number): number =>
      columnsUE.push({
        title: dayjs(dateFrom).add(i, 'day').format('YYYY-MM-DD'),
        dataIndex: dayjs(dateFrom).add(i, 'day').format('YYYY-MM-DD'),
        render: (text: CountPrice | undefined): JSX.Element => {
          const { count, price, deltaCount, deltaPrice } = text || {};

          return (
            <>
              <Row>
                <Col span={12}>
                  <Tag color={getColorByCount(deltaCount)}>
                    <Popover
                      trigger="hover"
                      placement="top"
                      content="Delta stock count by previous day."
                    >
                      {deltaCount || 0}
                    </Popover>
                  </Tag>
                </Col>
                <Col span={12}>
                  <Tag color={getColorByCount(deltaPrice)}>
                    <Popover
                      trigger="hover"
                      placement="top"
                      content="Delta price by previous day."
                    >
                      {deltaPrice || 0}
                    </Popover>
                  </Tag>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Popover
                    trigger="hover"
                    placement="bottom"
                    content="Actual stock count for day."
                  >
                    {count || 0}
                  </Popover>
                </Col>
                <Col span={12}>
                  <Popover
                    trigger="hover"
                    placement="bottom"
                    content="Actual price for day."
                  >
                    {price || 0}
                  </Popover>
                </Col>
              </Row>
            </>
          );
        },
      }),
    );

    setColumns(columnsUE);

    const dataSourceUE: DataType[] = Object.keys(report)
      .map((uuid: string): DataType | null => {
        if (!Report.warehouseProductTrend) {
          return null;
        }

        const item: IReportWarehouseProduct = report[uuid];

        const row: DataType = {
          key: uuid,
          warehouseProductUuid: uuid,
          name: item.name,
        };

        item.trends.forEach(
          (reportItem: IReportWarehouseProductTrend): void => {
            row[reportItem.date] = {
              count: reportItem.count,
              price: reportItem.price,
              deltaCount: reportItem.delta.count,
              deltaPrice: reportItem.delta.price,
            };
          },
        );

        return row;
      })
      .filter(notEmpty);

    setDataSource(dataSourceUE);
  }, [
    Report.warehouseProductTrend,
    dayInterval,
    warehouseProductsCount,
    Report.updatedAt,
  ]);

  return (
    <>
      <Typography>
        <Title level={2}>Report warehouse product trend</Title>
      </Typography>

      <Divider />

      <Space size={16}>
        <Select
          style={{ width: 200 }}
          placeholder="Select warehouse"
          showSearch
          optionFilterProp="children"
          options={warehouseOptions}
          onChange={(value: string): void => {
            setWarehouseUuid(value);
          }}
        />

        <Space size={16} direction="vertical">
          <Select
            style={{ width: 200 }}
            placeholder="Select days ago"
            showSearch
            optionFilterProp="children"
            options={[
              { label: '3 days ago', value: '3' },
              { label: '7 days ago', value: '7' },
              { label: '14 days ago', value: '14' },
              { label: '30 days ago', value: '30' },
            ]}
            value={String(dayInterval)}
            onChange={(value: string): void => {
              setDayInterval(Number(value));
            }}
          />

          <Select
            style={{ width: 200 }}
            placeholder="Select scenario"
            showSearch
            optionFilterProp="children"
            options={[
              {
                label: 'ABSOLUTE_COUNT_X_PRICE',
                value: 'ABSOLUTE_COUNT_X_PRICE',
              },
              {
                label: 'SELL_COUNT',
                value: 'SELL_COUNT',
              },
              {
                label: 'BROUGHT_COUNT',
                value: 'BROUGHT_COUNT',
              },
            ]}
            value={String(scenario)}
            onChange={(value: string): void => {
              setScenario(value);
            }}
          />
        </Space>

        <Space size={16} direction="vertical">
          <Select
            style={{ width: 200 }}
            placeholder="Select count of warehouse products"
            showSearch
            optionFilterProp="children"
            options={[
              { label: '100 warehouse products', value: '100' },
              { label: '200 warehouse products', value: '200' },
              { label: '500 warehouse products', value: '500' },
              { label: '1000 warehouse products', value: '1000' },
              { label: '2000 warehouse products', value: '2000' },
            ]}
            value={String(warehouseProductsCount)}
            onChange={(value: string): void => {
              setwarehouseProductsCount(Number(value));
            }}
          />

          <Select
            style={{ width: 200 }}
            placeholder="Select exclude offered products"
            showSearch
            optionFilterProp="children"
            options={[
              { label: 'Exclude offered products', value: 'true' },
              { label: 'Include offered products', value: 'false' },
            ]}
            value={String(excludeAssignedProducts)}
            onChange={(value: string): void => {
              setExcludeAssignedProducts(value === 'true');
            }}
          />
        </Space>

        <Space size={16} direction="vertical">
          <Input
            type="number"
            step="0.001"
            addonBefore="Minimal weight"
            value={minimalWeight}
            addonAfter="kg"
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              setMinimalWeight(Number(event.target.value));
            }}
          />

          <Input
            type="number"
            step="0.001"
            addonBefore="Maximal weight"
            value={maximalWeight}
            addonAfter="kg"
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              setMaximalWeight(Number(event.target.value));
            }}
          />
        </Space>

        <Space size={16} direction="vertical">
          <Input
            type="number"
            step="0.001"
            addonBefore="Minimal price"
            value={minimalPrice}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              setMinimalPrice(Number(event.target.value));
            }}
          />

          <Input
            type="number"
            step="0.001"
            addonBefore="Maximal price"
            value={maximalPrice}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              setMaximalPrice(Number(event.target.value));
            }}
          />
        </Space>

        <Button
          type="primary"
          icon={<ReloadOutlined />}
          disabled={!warehouseUuid}
          onClick={(): void => {
            if (!warehouseUuid) {
              return;
            }

            dispatch(
              reportWarehouseProductTrendLoad(
                warehouseUuid,
                dayInterval,
                warehouseProductsCount,
                excludeAssignedProducts,
                minimalWeight,
                maximalWeight,
                minimalPrice,
                maximalPrice,
                scenario,
              ),
            );
          }}
        >
          Load
        </Button>
      </Space>

      <Divider />

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Report.inProcess}
      />
    </>
  );
}

const mapStateToProps = ({ Report, Warehouse }: RootState) => ({
  Report,
  Warehouse,
});

export default connect(mapStateToProps)(ReportWarehouseProductTrendContainer);
