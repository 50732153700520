import { Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';

export function getUpdatedAtMessage(
  wasUpdated: boolean,
  updatedAt: number | undefined,
): JSX.Element {
  return (
    <Typography>
      {wasUpdated && `Updated at ${dayjs(updatedAt).fromNow()}`}
      {!wasUpdated && `Not updated yet!`}
    </Typography>
  );
}

export function renderUpdatedAtMessage(
  updatedAt: number | undefined,
  inProcess: boolean,
  condition: () => boolean,
): JSX.Element {
  const [wasUpdated, setWasUpdated] = React.useState(false);

  useEffect((): void => {
    if (condition() && updatedAt) {
      setWasUpdated(true);
    }
  }, [updatedAt, condition, inProcess]);

  return getUpdatedAtMessage(wasUpdated, updatedAt);
}
