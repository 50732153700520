import { green, red } from '@ant-design/colors';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { formatCurrency } from '@mahawi/eshop-common/dist/src/format-currency';
import { priceExchangeCalculation } from '@mahawi/eshop-common/dist/src/price-exchange-calculation';
import { round } from '@mahawi/eshop-common/dist/src/round';
import {
  type ICurrencyType,
  type IExchangeRate,
  type IProductPrices,
  type IProductWarehouse,
} from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Form,
  InputNumber,
  Row,
  Space,
  Typography,
} from 'antd';
import { DescriptionsItemType } from 'antd/es/descriptions';
import { NotificationPlacements } from 'antd/es/notification/interface';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { NOTIFICATION_SEVERITY } from '../../../admin-types';
import {
  type OpenNotificationType,
  withNotification,
} from '../../notification';
import { type ICurrencyState } from '../../reducers/currency/types';
import {
  currenciesPricesUpdate,
  productRecalculaterPrice,
} from '../../reducers/product/actions';
import {
  type ICurrencyPrices,
  type IProductState,
} from '../../reducers/product/types';

interface IForm {
  prices: { [key: string]: number };
}

const { Panel } = Collapse;
const { Text } = Typography;

const ISO_CODE_EUR = 'EUR';
const VAT_RATE = 1.21;

function Prices({
  Product,
  Currency,
  dispatch,
  openNotification,
}: {
  Product: IProductState;
  Currency: ICurrencyState;
  dispatch: Dispatch;
  openNotification: OpenNotificationType;
}): JSX.Element {
  const [form] = Form.useForm<IForm>();

  const [pricesEls, setPricesEls] = useState<JSX.Element[]>([]);
  const [prices, setPrices] = useState<{ [key: string]: number }>({});
  const [profitItems, setProfitItems] = useState<DescriptionsItemType[]>([]);
  const [profitString, setProfitString] = useState<string>('---');
  const [currencyTypeEUR, setCurrencyTypeEUR] = useState<
    ICurrencyType | undefined
  >(undefined);

  const onFinish = useCallback(
    ({ prices }): void => {
      const currencyPrices: ICurrencyPrices[] = [];

      const isoCodes: string[] = Object.keys(prices);

      isoCodes.map((isoCode: string): number =>
        currencyPrices.push({ price: prices[isoCode], isoCode }),
      );

      dispatch(currenciesPricesUpdate(Product.uuid, currencyPrices));
    },
    [Product.uuid, dispatch],
  );

  const calcNewPrice = useCallback(
    (value: number): void => {
      Currency.exchangeRates.forEach(
        ({ isoCode, rate }: IExchangeRate): void => {
          const currency: ICurrencyType | undefined = Currency.currencies.find(
            (c: ICurrencyType): boolean => c.isoCode === isoCode,
          );

          if (!currency) {
            throw new Error('Currency not found!');
          }

          let newPrice: number = 0;

          try {
            newPrice = priceExchangeCalculation({
              priceEUR: value,
              rate,
              increaseRate: currency.increaseRate || 1,
              decimalPrecision: currency.decimalPrecision || 2,
            });
          } catch (error) {
            let message: string = 'Error in price calculation!';
            if (error instanceof Error) {
              message = error.message;
            }

            openNotification(NotificationPlacements[2], {
              title: 'Error',
              description: message,
              severity: NOTIFICATION_SEVERITY.ERROR,
            });
          }

          dispatch(productRecalculaterPrice(currency.isoCode, newPrice));
          form.setFieldValue(['prices', currency.isoCode], newPrice);
        },
      );
    },
    [Currency.currencies, Currency.exchangeRates, dispatch],
  );

  const recalculatePrice = useCallback(
    (e): void => {
      const {
        target: {
          value,
          dataset: { currencyIsoCode },
        },
      } = e;

      const euro: ICurrencyType | undefined = Currency.currencies.find(
        ({ isoCode }: ICurrencyType): boolean => isoCode === ISO_CODE_EUR,
      );

      if (!euro) {
        throw new Error('Euro currency not found!');
      }

      const isEuro: boolean = euro.isoCode === currencyIsoCode;

      if (isEuro) {
        dispatch(productRecalculaterPrice(euro.isoCode, parseFloat(value)));
        calcNewPrice(value);
      } else {
        const exchangeRate: IExchangeRate | undefined =
          Currency.exchangeRates.find(
            ({ isoCode }: IExchangeRate): boolean =>
              isoCode === currencyIsoCode,
          );

        if (!exchangeRate) {
          throw new Error(`Exchange rate for ${currencyIsoCode} not found!`);
        }

        let euroPrice: number =
          Math.round(
            (value / exchangeRate.rate) * 10 ** (euro.decimalPrecision || 2),
          ) /
          10 ** (euro.decimalPrecision || 2);

        if (euroPrice < 0.01) {
          euroPrice = 0.01;
        }

        dispatch(productRecalculaterPrice(euro.isoCode, euroPrice));

        calcNewPrice(euroPrice);
      }
    },
    [Currency.currencies, Currency.exchangeRates, calcNewPrice, dispatch],
  );

  useEffect((): void => {
    const pricesElsUE: JSX.Element[] = Currency.currencies.map(
      (currency: ICurrencyType): JSX.Element => {
        const exchangeRate: IExchangeRate | undefined =
          Currency.exchangeRates.find(
            ({ isoCode }: IExchangeRate): boolean =>
              isoCode === currency.isoCode,
          );

        return (
          <Row gutter={16} key={currency.isoCode}>
            <Col span={24}>
              <Space direction="vertical">
                <Text>{currency.name}</Text>
              </Space>
            </Col>

            <Col span={6}>
              <Form.Item name={['prices', currency.isoCode]}>
                <InputNumber
                  data-currency-iso-code={currency.isoCode}
                  addonBefore={
                    currency.isSymbolBeforePrice ? currency.symbol : ''
                  }
                  addonAfter={
                    currency.isSymbolBeforePrice ? '' : currency.symbol
                  }
                  disabled={Product.inProcess}
                  precision={currency.decimalPrecision}
                  onBlur={recalculatePrice}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Space direction="vertical">
                <Text>
                  {exchangeRate && `Exchange rate: ${exchangeRate.rate}`}
                </Text>
              </Space>
            </Col>

            <Col span={6}>
              <Space direction="vertical">
                <Text>{`Increase rate: ${currency.increaseRate} %`}</Text>
              </Space>
            </Col>
          </Row>
        );
      },
    );

    setPricesEls(pricesElsUE);
  }, [Currency.currencies, Currency.exchangeRates, Product.inProcess, Product]);

  useEffect((): void => {
    const pricesUE: { [key: string]: number } = {};

    Product.prices.forEach((price: IProductPrices): void => {
      pricesUE[price.currencyType.isoCode] = price.price;
    });

    setPrices(pricesUE);
  }, [form, Product.prices, Product.updatedAt]);

  const [statusIcon, setStatusIcon] = useState<JSX.Element>(
    <CloseCircleOutlined style={{ color: red.primary }} />,
  );

  useEffect((): void => {
    const isAlLPricesValid: boolean = Product.prices.every(
      ({ price }: IProductPrices): boolean => price > 0,
    );

    if (isAlLPricesValid) {
      setStatusIcon(<CheckCircleOutlined style={{ color: green.primary }} />);
    } else {
      setStatusIcon(<CloseCircleOutlined style={{ color: red.primary }} />);
    }
  }, [Product.prices, Product.updatedAt]);

  useEffect((): void => {
    const currencyTypeEURUE: ICurrencyType | undefined =
      Currency.currencies.find(
        (c: ICurrencyType): boolean => c.isoCode === ISO_CODE_EUR,
      );

    if (!currencyTypeEURUE) {
      return;
    }

    setCurrencyTypeEUR(currencyTypeEURUE);
  }, [Currency.currencies]);

  useEffect((): void => {
    if (!currencyTypeEUR) {
      return;
    }

    const warehouse: IProductWarehouse | undefined = Product.warehouses.find(
      (w: IProductWarehouse): boolean => w.warehouse.isPrimary,
    );

    const productWarehousesRecommended: IProductWarehouse[] = [
      ...Product.warehouses,
    ]
      .sort(
        (a: IProductWarehouse, b: IProductWarehouse): number =>
          a.pricesEUR.recommended - b.pricesEUR.recommended,
      )
      .filter((w: IProductWarehouse): boolean => w.pricesEUR.recommended > 0);

    const recomemndedPriceEURUE: number =
      productWarehousesRecommended.length === 0
        ? 0
        : productWarehousesRecommended[0].pricesEUR.recommended;

    if (!warehouse || !recomemndedPriceEURUE) {
      return;
    }

    const sellingPriceEURWithVAT: number | undefined = Product.prices.find(
      ({ currencyType }: IProductPrices): boolean =>
        currencyType.isoCode === ISO_CODE_EUR,
    )?.price;

    if (!sellingPriceEURWithVAT) {
      return;
    }

    const sellingPriceUE: number = round(sellingPriceEURWithVAT / VAT_RATE, 4);
    const purchasePriceEUUE: number = warehouse?.pricesEUR.purchase || 0;

    let profitUE: number = 0;
    let profitPercentUE: number = 0;

    if (purchasePriceEUUE > 0) {
      profitUE = sellingPriceUE - purchasePriceEUUE;
      profitPercentUE = (profitUE / purchasePriceEUUE) * 100;
    }

    const profit: string = `${formatCurrency(profitUE, currencyTypeEUR)} (${profitPercentUE.toFixed(4)} %)`;

    setProfitString(profit);

    setProfitItems([
      {
        key: 'sellingPrice',
        label: 'Selling',
        children: formatCurrency(sellingPriceUE, currencyTypeEUR),
        span: { xs: 24, sm: 12, md: 8 },
      },
      {
        key: 'recommendedPrice',
        label: 'Recomemnded',
        children: formatCurrency(recomemndedPriceEURUE, currencyTypeEUR),
        span: { xs: 24, sm: 12, md: 8 },
      },
      {
        key: 'purchasePrice',
        label: 'Purchase',
        children: formatCurrency(purchasePriceEUUE, currencyTypeEUR),
        span: { xs: 24, sm: 12, md: 8 },
      },
      {
        key: 'profit',
        label: 'Profit',
        children: profit,
        span: { xs: 24, sm: 12, md: 8 },
      },
    ]);
  }, [Product.prices, Product.warehouses, Product.updatedAt, currencyTypeEUR]);

  const initialValues = {
    prices,
  };

  return (
    <Collapse>
      <Panel
        header={
          <Space size={8} align="start" direction="horizontal">
            <strong>{`Prices with VAT, profit without VAT ${profitString}`}</strong>
            {statusIcon}
          </Space>
        }
        key="prices"
      >
        <Row>
          <Col span={12}>
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              initialValues={initialValues}
            >
              {pricesEls}

              <Button
                type="primary"
                htmlType="submit"
                disabled={Product.inProcess}
                block
              >
                Submit
              </Button>
            </Form>
          </Col>

          <Col span={12}>
            <Descriptions
              title="Profit calculation without VAT"
              bordered
              items={profitItems}
            />
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ Product, Currency }: RootState) => ({
  Product,
  Currency,
});

export default withNotification(connect(mapStateToProps)(Prices));
