import {
  type EOrderStatus,
  type IOrderByOrderStatusTyperCode,
} from '@mahawi/eshop-common/dist/src/types';
import { createAction } from '@reduxjs/toolkit';

import { ORDERS_ACTION } from './types';

export const ordersLoadByOrderStatusCodes = createAction(
  ORDERS_ACTION.LOAD,
  (orderStatusTypeCode: EOrderStatus[], limit: number) => ({
    payload: {
      orderStatusType: {
        codes: orderStatusTypeCode,
        limit,
      },
    },
  }),
);

export const ordersLoadByOrderStatusCodesResponse = createAction(
  ORDERS_ACTION.LOAD_RESPONSE,
  (orders: IOrderByOrderStatusTyperCode[]) => ({
    payload: {
      orders,
    },
  }),
);

export const ordersLoadAllOrderStatusTypeCodes = createAction(
  ORDERS_ACTION.LOAD_ALL_ORDER_STATUS_TYPE_CODES,
);

export const ordersLoadAllOrderStatusTypeCodesResponse = createAction(
  ORDERS_ACTION.LOAD_ALL_ORDER_STATUS_TYPE_CODES_RESPONSE,
  (statusTypeCodes: string[]) => ({
    payload: {
      statusTypeCodes,
    },
  }),
);

export const ordersLoadAllReviewStatusTypeCodes = createAction(
  ORDERS_ACTION.LOAD_ALL_REVIEW_STATUS_TYPE_CODES,
);

export const ordersLoadAllReviewStatusTypeCodesResponse = createAction(
  ORDERS_ACTION.LOAD_ALL_REVIEW_STATUS_TYPE_CODES_RESPONSE,
  (statusTypeCodes: string[]) => ({
    payload: {
      statusTypeCodes,
    },
  }),
);

export const ordersReset = createAction(ORDERS_ACTION.RESET);
