import {
  type IAdminWarehouseProductList,
  type IFeatureFlag,
  type IProduct,
  type IReportWarehouseProductToImport,
  type IWarehouseProductFeatureFlag,
} from '@mahawi/eshop-common/dist/src/types';
import { type ColumnFilterItem } from 'antd/es/table/interface';
import { Key } from 'react';

import { type IFeatureFlagsState } from '../reducers/feature-flags/types';

type Product =
  | IAdminWarehouseProductList
  | IReportWarehouseProductToImport
  | IProduct;

const FILTER_WITH_FEATURE_FLAGS: string = 'withFeatureFlags';
const FILTER_WITHOUT_FEATURE_FLAGS: string = 'withoutFeatureFlags';

export function getTableFeatureFlagsColumnFilters({
  dataSource,
  FeatureFlags,
}: {
  dataSource:
    | IAdminWarehouseProductList[]
    | IReportWarehouseProductToImport[]
    | IProduct[];
  FeatureFlags: IFeatureFlagsState;
}): ColumnFilterItem[] {
  const columnFilterItemFeatureFlags: Map<string, ColumnFilterItem> = new Map<
    string,
    ColumnFilterItem
  >();

  columnFilterItemFeatureFlags.set(FILTER_WITH_FEATURE_FLAGS, {
    text: 'With feature flags',
    value: FILTER_WITH_FEATURE_FLAGS,
  });

  columnFilterItemFeatureFlags.set(FILTER_WITHOUT_FEATURE_FLAGS, {
    text: 'Without feature flags',
    value: FILTER_WITHOUT_FEATURE_FLAGS,
  });

  dataSource.forEach((product: Product): void => {
    product.featureFlags?.forEach((ff: IWarehouseProductFeatureFlag): void => {
      if (columnFilterItemFeatureFlags.has(ff.code)) {
        return;
      }

      const eff: string | undefined = FeatureFlags.featureFlags?.find(
        (f: IFeatureFlag): boolean => f.code === ff.code,
      )?.name;

      columnFilterItemFeatureFlags.set(ff.code, {
        text: eff ? eff : ff.code,
        value: ff.code,
      });
    });
  });

  return Array.from(columnFilterItemFeatureFlags.values());
}

export function getTableFeatureFlagsColumnOnFilter(
  value: boolean | Key,
  record: Product,
): boolean {
  if (value === FILTER_WITH_FEATURE_FLAGS) {
    return record.featureFlags?.length > 0;
  }

  if (value === FILTER_WITHOUT_FEATURE_FLAGS) {
    return record.featureFlags?.length === 0;
  }

  return record.featureFlags?.some(
    (ff: IWarehouseProductFeatureFlag): boolean => ff.code === value,
  );
}
