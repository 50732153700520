import { type IAdminCategory } from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum CATEGORIES_ACTION {
  ADD = 'CATEGORY_ADD',
  GET_LIKELY_PRODUCT_CATEGORIES = 'GET_LIKELY_PRODUCT_CATEGORIES',
  GET_LIKELY_PRODUCT_CATEGORIES_RESPONSE = 'GET_LIKELY_PRODUCT_CATEGORIES_RESPONSE',
  LOAD_ALL = 'CATEGORY_LOAD_ALL',
  LOAD_ALL_RESPONSE = 'CATEGORY_LOAD_ALL_RESPONSE',
}

export interface IAdd extends UnknownAction {
  type: CATEGORIES_ACTION.ADD;
  payload: {
    name: string;
    languageType: {
      code: string;
    };
    parentCategoryUuid?: string;
  };
}

export interface IGetLikelyProductCategories extends UnknownAction {
  type: CATEGORIES_ACTION.GET_LIKELY_PRODUCT_CATEGORIES;
  payload: {
    category: {
      uuids: string[];
    };
  };
}

export interface IGetLikelyProductCategoriesResponse extends UnknownAction {
  type: CATEGORIES_ACTION.GET_LIKELY_PRODUCT_CATEGORIES_RESPONSE;
  payload: {
    category: {
      uuids: string[];
    };
  };
}

export interface IGetLikelyProductCategoriesCallback {
  (arg0: IGetLikelyProductCategoriesResponse): void;
}

export interface ICategoryLoadAll extends UnknownAction {
  type: CATEGORIES_ACTION.LOAD_ALL;
}

export interface ICategoryLoadAllResponse extends UnknownAction {
  type: CATEGORIES_ACTION.LOAD_ALL_RESPONSE;
  payload: {
    categories: IAdminCategory[];
  };
}

export interface ICategoryLoadAllCallback {
  (arg0: ICategoryLoadAllResponse): void;
}

export interface ICategoriesState {
  inProcess: boolean;
  categories?: IAdminCategory[];
  updatedAt?: number;
  likelyProductCategories: {
    uuids?: string[];
  };
}
