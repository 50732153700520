import {
  Button,
  Descriptions,
  Divider,
  List,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { type Dispatch } from 'redux';

import CopyToClipboard from '../components/copy-to-clipboard';
import { type RootState } from '../reducers';
import { downloaderLoadWarehouseProperty } from '../reducers/downloader/actions';
import { type IDownloaderState } from '../reducers/downloader/types';

function DownloaderWarehouseProperty({
  dispatch,
  Downloader,
}: {
  dispatch: Dispatch;
  Downloader: IDownloaderState;
}): JSX.Element {
  const { rawName } = useParams();
  const [showPropertyValues, setShowPropertyValues] = useState<boolean>(false);

  const reload = useCallback((): void => {
    if (rawName) {
      dispatch(downloaderLoadWarehouseProperty(rawName));
    }
  }, [dispatch, rawName]);

  useEffect((): void => {
    reload();
  }, [reload]);

  if (!Downloader.property) {
    return <Skeleton active />;
  }

  return (
    <>
      <Typography.Title level={2}>Warehouse property</Typography.Title>

      <Divider />

      <Descriptions
        bordered
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="Raw name">
          <Space size={16}>
            {Downloader.property.rawName}

            <CopyToClipboard text={Downloader.property.rawName} type="link" />
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Name">
          <Space size={16}>
            {Downloader.property.name}

            <CopyToClipboard text={Downloader.property.name} type="link" />
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Unit">
          <Space size={16}>
            {Downloader.property.unit || '---'}

            {Downloader.property.unit && (
              <CopyToClipboard text={Downloader.property.unit} type="link" />
            )}
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Values">
          <Space size={16} align="start" direction="vertical">
            <Space size={16}>
              <Typography.Text>
                {Downloader.property.values?.length || 0} items
              </Typography.Text>

              <Button
                color="primary"
                onClick={() => setShowPropertyValues(!showPropertyValues)}
              >
                {showPropertyValues ? 'Hide' : 'Show'}
              </Button>
            </Space>

            {showPropertyValues && (
              <List
                size="small"
                bordered
                dataSource={Downloader.property.values}
                renderItem={(item: string): JSX.Element => (
                  <List.Item>
                    <Space size={16}>
                      {item}

                      <CopyToClipboard text={item} type="link" />
                    </Space>
                  </List.Item>
                )}
              />
            )}
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}

const mapStateToProps = ({ Downloader, Language }: RootState) => ({
  Downloader,
  Language,
});

export default connect(mapStateToProps)(DownloaderWarehouseProperty);
