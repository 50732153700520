import { formatCurrency } from '@mahawi/eshop-common/dist/src/format-currency';
import { getFlag } from '@mahawi/eshop-common/dist/src/get-flag';
import {
  type IOrderByOrderStatusTyperCode,
  type ITag,
} from '@mahawi/eshop-common/dist/src/types';
import { Space, Tag, Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import React, { type Key, useEffect, useState } from 'react';

import { type IOrdersState } from '../../reducers/orders/types';
import { type ITagsState } from '../../reducers/tags/types';
import CopyToClipboard from '../copy-to-clipboard';
import GoogleMapsButton from '../google-maps-button';
import Link from '../link';
import Table from '../table';

interface DataType extends IOrderByOrderStatusTyperCode {
  key: string;
}

function OrdersTable({
  Orders,
  Tags,
}: {
  Orders: IOrdersState;
  Tags: ITagsState;
}): JSX.Element {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);

  useEffect((): void => {
    if (!Orders.orders || Orders.inProcess) {
      setDataSource([]);
      return;
    }

    const dataSourceUE: DataType[] = Orders.orders.map(
      (order: IOrderByOrderStatusTyperCode): DataType => ({
        key: order.uuid,
        uuid: order.uuid,
        price: order.price,
        thirdPartyPaymentIds: order.thirdPartyPaymentIds,
        deliveryFee: order.deliveryFee,
        createdAt: order.createdAt,
        updatedAt: order.updatedAt,
        currencyType: order.currencyType,
        billingAddress: order.billingAddress,
        review: {
          uuid: order.review?.uuid,
          reviewStatusType: {
            code: order.review?.reviewStatusType.code,
          },
        },
        tags: order.tags,
      }),
    );

    setDataSource(dataSourceUE);
  }, [
    Orders.orders,
    Orders.updatedAt,
    Orders.inProcess,
    Orders.orders?.length,
  ]);

  useEffect((): void => {
    const filterIsAvailable: boolean = dataSource.length > 0;

    const columnsUE: ColumnsType<object> = [
      {
        title: 'UUID',
        dataIndex: 'uuid',
        sorter: (a: DataType, b: DataType): number =>
          a.uuid.localeCompare(b.uuid),
        filterSearch: true,
        filters: filterIsAvailable
          ? [
              ...new Set(dataSource.map(({ uuid }: DataType): string => uuid)),
            ].map(
              (uuid: string): ColumnFilterItem => ({
                text: uuid,
                value: uuid,
              }),
            )
          : undefined,
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.uuid === value,
        render: (_text: string, record: DataType): JSX.Element => {
          const uuidSegments: string[] = record.uuid.split('-');
          const firstSegment: string = uuidSegments[0];

          return (
            <Space size={4} align="center">
              <Link to={`/order/${record.uuid}`} label={record.uuid} />
              <CopyToClipboard text={record.uuid} type="link" />
              <CopyToClipboard
                text={firstSegment}
                type="text"
                title="Copy short order UUID"
              />
            </Space>
          );
        },
      },
      {
        title: 'Billing address',
        dataIndex: 'billingAddress',
        sorter: (a: DataType, b: DataType): number =>
          a.billingAddress.surname.localeCompare(b.billingAddress.surname),
        filterSearch: true,
        filters: filterIsAvailable
          ? [
              ...new Set(
                dataSource
                  .map(
                    ({ billingAddress }: DataType): string =>
                      billingAddress.surname,
                  )
                  .sort((a: string, b: string): number => a.localeCompare(b)),
              ),
            ].map(
              (surname: string): ColumnFilterItem => ({
                text: surname,
                value: surname,
              }),
            )
          : undefined,
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.billingAddress.surname.includes(value.toString()),
        render: (_text: string, record: DataType): JSX.Element => {
          return (
            <Space align="start">
              <Typography>
                {record.billingAddress.surname} {record.billingAddress.name},{' '}
                {record.billingAddress.city},{' '}
                {record.billingAddress.countryTypeIsoCode}{' '}
                {getFlag({
                  countryTypeIsoCode: record.billingAddress.countryTypeIsoCode,
                })}
              </Typography>
              <GoogleMapsButton address={record.billingAddress} />
            </Space>
          );
        },
      },
      {
        title: 'Created at',
        dataIndex: 'createdAt',
        sorter: (a: DataType, b: DataType): number =>
          dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
        filterSearch: true,
        filters: filterIsAvailable
          ? [
              ...new Set(
                dataSource.map(({ createdAt }: DataType): string =>
                  dayjs(createdAt).startOf('day').format('YYYY-MM-DD'),
                ),
              ),
            ]
              .sort((a: string, b: string): number => b.localeCompare(a))
              .map(
                (createdAt: string): ColumnFilterItem => ({
                  text: dayjs(createdAt).format('YYYY-MM-DD'),
                  value: dayjs(createdAt).unix(),
                }),
              )
          : undefined,
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          dayjs(record.createdAt).startOf('day').unix() === value,
        render: (text: string): string =>
          dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: 'Updated at',
        dataIndex: 'updatedAt',
        sorter: (a: DataType, b: DataType): number =>
          dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
        filterSearch: true,
        filters: filterIsAvailable
          ? [
              ...new Set(
                dataSource.map(({ createdAt }: DataType): string =>
                  dayjs(createdAt).startOf('day').format('YYYY-MM-DD'),
                ),
              ),
            ]
              .sort((a: string, b: string): number => b.localeCompare(a))
              .map(
                (createdAt: string): ColumnFilterItem => ({
                  text: dayjs(createdAt).format('YYYY-MM-DD'),
                  value: dayjs(createdAt).unix(),
                }),
              )
          : undefined,
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          dayjs(record.createdAt).startOf('day').unix() === value,
        render: (text: string): string =>
          dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: 'Price',
        dataIndex: 'price',
        sorter: (a: DataType, b: DataType): number => a.price - b.price,
        filterSearch: true,
        filters: filterIsAvailable
          ? [
              ...new Set(
                dataSource
                  .map(({ price }: DataType): number => price)
                  .sort((a: number, b: number): number => a - b),
              ),
            ].map(
              (price: number): ColumnFilterItem => ({
                text: price,
                value: price,
              }),
            )
          : undefined,
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.price === value,
        render: (text: number, record: DataType): string =>
          formatCurrency(text, record.currencyType),
      },
      {
        title: 'Delivery fee',
        dataIndex: 'deliveryFee',
        sorter: (a: DataType, b: DataType): number =>
          a.deliveryFee - b.deliveryFee,
        filterSearch: true,
        filters: filterIsAvailable
          ? [
              ...new Set(
                dataSource
                  .map(({ deliveryFee }: DataType): number => deliveryFee)
                  .sort((a: number, b: number): number => a - b),
              ),
            ].map(
              (deliveryFee: number): ColumnFilterItem => ({
                text: deliveryFee,
                value: deliveryFee,
              }),
            )
          : undefined,
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.deliveryFee === value,
        render: (text: number, record: DataType): string =>
          formatCurrency(text, record.currencyType),
      },
      {
        title: 'Review',
        dataIndex: 'review',
        sorter: (a: DataType, b: DataType): number =>
          a.review.reviewStatusType.code?.localeCompare(
            b.review.reviewStatusType.code || '',
          ) || 0,
        filterSearch: true,
        filters: filterIsAvailable
          ? [
              ...new Set(
                dataSource
                  .map(
                    ({ review }: DataType): string =>
                      review.reviewStatusType.code || '',
                  )
                  .sort((a: string, b: string): number => a.localeCompare(b)),
              ),
            ].map(
              (review: string): ColumnFilterItem => ({
                text: review,
                value: review,
              }),
            )
          : undefined,
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.review.reviewStatusType.code === value,
        render: (_text: string, record: DataType): string => {
          if (record.review.reviewStatusType.code) {
            return record.review.reviewStatusType.code;
          }

          return '---';
        },
      },
      {
        title: 'Third party payment ID',
        dataIndex: 'thirdPartyPaymentId',
        filterSearch: true,
        render: (_text: string, record: DataType): string => {
          if (record.thirdPartyPaymentIds) {
            return record.thirdPartyPaymentIds.join(', ');
          }

          return '';
        },
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        filterSearch: true,
        render: (
          _text: string,
          record: DataType,
        ): JSX.Element[] | undefined => {
          if (record.tags) {
            const els: JSX.Element[] = record.tags.map(
              (tag: string): JSX.Element => {
                return (
                  <Tag
                    key={tag}
                    color={
                      Tags.tags?.find((t: ITag): boolean => t.color === tag)
                        ?.color
                    }
                  >
                    {
                      Tags.tags?.find((t: ITag): boolean => t.color === tag)
                        ?.name
                    }
                  </Tag>
                );
              },
            );

            return els;
          }

          return undefined;
        },
      },
    ];

    setColumns(columnsUE);
  }, [
    Orders.orders,
    Orders.updatedAt,
    Orders.inProcess,
    Orders.orders?.length,
    dataSource,
    dataSource.length,
  ]);

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      isLoading={Orders.inProcess}
    />
  );
}

export default OrdersTable;
